// Be sure you ONLY uncomment the enviro config you are working against (i.e.: local, dev, int, pro -only one!!!)

// LOCAL Environment
/*
export const properties = {
  host: "https://api.dev.motivators.adpi.bbvanext.tech/",
  idCaptcha: "6LcePjcaAAAAALllvI902iIoVQICnhtplTCCbR0m",
  env: "LOCAL"
};
*/

// DEV Environment
/*
export const properties = {
  host: "https://api.dev.motivators.adpi.bbvanext.tech/",
  idCaptcha: "6Ld0JtIZAAAAAEzBdTWQIt7Vq0ISHRJ6ugF6Jip7",
  env: "DEV"
};
*/

// INT Environment 
/*
export const properties = {
  host: "https://api.int.motivators.adpi.bbvanext.tech/",
  idCaptcha: "6LdXj88ZAAAAADZF5vBW3vZC7Dai_ESRwy1Isdb2",
  env: "INT"
};
*/

// PRO Environment
export const properties = {
  host: "https://api-motivators.bbvanexttechnologies.com/",
  idCaptcha: "6LcvWs4ZAAAAALewOvPkk_JTU3FBXSTfzyV3Gmev",
  env: "PRO"
};
